.a4 {
  background-color: $white;

  // Give the text canvas a4 feel with 21cm width and 2.4cm margins.
  width: $text-a4-width;
  padding: $text-a4-margin;

  // Left gutter is special because it has collapse button
  $text-collapser-width: 1cm;
  padding-left: $text-a4-margin - $text-collapser-width;

  .text-collapser {
    width: $text-collapser-width;
  }

  // Remove gutters from rows and cols as we already have A4 margin.
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col,
  .col-auto,
  .container,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
