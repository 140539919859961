// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import 'variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ama-studio-primary: mat-palette($byggtjanst-primary-palette);
$ama-studio-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ama-studio-warn: mat-palette($byggtjanst-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ama-studio-theme: mat-light-theme(
  (
    color: (
      primary: $ama-studio-primary,
      accent: $ama-studio-accent,
      warn: $ama-studio-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ama-studio-theme);

// Override font family and increase line height so letters are not cut-off
$custom-typography: mat-typography-config(
  $font-family: $byggtjanst-font-family,
  $input: mat-typography-level(inherit, $line-height-base, inherit),
);

@include angular-material-typography($custom-typography);

// Place material components above bootstrap modal so it's seen.
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: $zindex-modal + 1;
}

// Add styling for white form-field
mat-form-field.fill-white {
  .mat-form-field-flex {
    background-color: $white;
  }
}

/**
  Fix autosize textarea having unneeded scrollbar
  https://github.com/angular/components/issues/12902#issuecomment-508787525
*/
textarea.mat-input-element.cdk-textarea-autosize {
  box-sizing: content-box;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $byggtjanst-gray-three !important;
  box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25) !important;
  border: 0.5px $byggtjanst-gray-three !important;
  border-radius: 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $byggtjanst-primary !important;
  box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25) !important;
  border: 0.5px $byggtjanst-gray-three !important;
  border-radius: 8px !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.7em 0 0.7em 0;
}
