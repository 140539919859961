@mixin make-sticky-canvas() {
  .canvas-content {
    height: calc(100vh - #{$header-navbar-height} - #{$canvas-header-height});

    &.export{
      height: calc(100% - 4rem);
    }
  }
}

@mixin setBoldFont() {
  b,
  strong,
  .fw-bold {
    font-family: $byggtjanst-font-family !important;
    font-weight: $font-weight-bold !important;
  }
}
