
@import 'custom-material';
@import 'custom-bootstrap';

@import 'mixins';
@import 'utilities.scss';
@import 'ngx-popperjs/css/theme-white.css';
@import 'quill/dist/quill.core.css';
@import 'quill-mention/dist/quill.mention.min.css';
@import 'bootstrap/scss/bootstrap';
@import 'a4';
@import 'typography';


html {
  overflow: hidden;
}

// body {
//   @include popperjs.ngx-popperjs-theme($background-color, $text-color, $max-width, $z-index);
// }

.btn-light {
  @include button-variant(
    $byggtjanst-gray-one,
    $border: $byggtjanst-gray-one,
    $color: $byggtjanst-gray-nine,
    $hover-background: $canvas-gray,
    $hover-border: transparent,
    $hover-color: $byggtjanst-gray-nine,
    $active-background: $canvas-gray,
    $active-border: transparent,
    $active-color: color-contrast($canvas-gray),
    $disabled-background: $byggtjanst-gray-one,
    $disabled-border: $byggtjanst-gray-one,
    $disabled-color: color-contrast($byggtjanst-gray-one)
  );
}

// Primary outline should look les like the regular primary button
.btn-outline-primary {
  @include button-outline-variant(
    $byggtjanst-primary,
    $color-hover: $byggtjanst-gray-one,
    $active-background: $byggtjanst-darkgreen,
    $active-border: transparent
  );
}

.btn-primary,
.btn-outline-primary {
  box-shadow: none;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: $byggtjanst-gray-one;
  }

  &:hover {
    background-color: $byggtjanst-lightgreen;
  }

  &:focus,
  &:active {
    background-color: $byggtjanst-darkgreen;
  }

  &:active,
  &:focus,
  &:active:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    background-color: $byggtjanst-gray !important;
    border-color: $byggtjanst-gray !important;
    color: $white;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.main-container {
  &__width {
    width: calc(100% - #{$menu-width});
  }
}

sbui-accordion {
  &.highlight,
  &.active {
    border: 1px solid #008746 !important;
  }
}

.cookiefirst-root > span {
  bottom: 140px !important;
}
