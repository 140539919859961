// Change base font size to 14px instead of 16px
html {
  font-size: $base-font-size;
}

// Sets the font for the whole page.
body {
  font-family: $byggtjanst-font-family;
}

@include setBoldFont;
