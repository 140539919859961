$utilities: ("custom-color": (property: color,
    class: text,
    values: (primary: $byggtjanst-primary,
      red: $byggtjanst-red,
      gray: $byggtjanst-gray,
      blue : $byggtjanst-blue,
      secondary: $byggtjanst-gray,
      green: $byggtjanst-primary,
      light: $byggtjanst-gray-two,
      dark: $gray-900,
      lighter: $byggtjanst-gray-lighter,
      purple: $byggtjanst-purple,
      muted: $byggtjanst-gray
      )),
  "custom-font-size": (property: font-size,
    class: text,
    values: (bigger: 20px,
      larger: 18px,
      large: 16px,
      medium: 14px,
      small: 12px,
      smaller: 11px,
      smallest: 10px,
      very-small: 9px,
      body: $body-text-font-size)),
  "custom-background-color": (property: background-color,
    class: bg,
    values: (gray-one: $byggtjanst-gray-one,
      gray-mid: $byggtjanst-gray-mid,
      gray-mid-light: $byggtjanst-gray-mid-light,
      gray-light: $byggtjanst-gray-light,
      gray: $byggtjanst-gray,
      secondary: $byggtjanst-gray,
      light: $byggtjanst-gray-two,
      dark: $gray-900,
      lighter: $byggtjanst-gray-lighter,
      green: $byggtjanst-primary
      )),
  "custom-line-height": (property: line-height,
    class: btn,
    values:(icon: $material-icon-line-height)));

.white-space-pre-line {
  white-space: pre-line;
}

// Limit image width to 100% so it doesn't expand outside its container.
.limit-image-width img {
  width: 100%;
  height: auto;
}

// Override any other font-family to match the rest of the site
.keep-font-family * {
  font-family: $byggtjanst-font-family !important;

  @include setBoldFont;
}

.keep-body-font-size * {
  font-size: $body-text-font-size !important;
}

.sticky-bottom {
  @extend .sticky-top;
  bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.border-x-0 {
  @extend .border-top-0;
  @extend .border-bottom-0;
}

.border-y-0 {
  @extend .border-end-0;
  @extend .border-start-0;
}

.multiline-truncate-short {
  -webkit-line-clamp: 5;
}

.multiline-truncate-long {
  -webkit-line-clamp: 15;
}

.multiline-truncate-short,
.multiline-truncate-long {
  // Truncate text and show ellipsis (...) after X rows
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.mirror {
  transform: scale(-1);
}

// Mimic the font size used for bootstrap button
.button-sized-font {
  font-size: 24px;
}

// Show item when hovering over parent item. E.g.for displaying pen-icon when hovering over
// editable content
.show-on-hover {
  visibility: hidden;
}

.hover-parent {
  &:hover {
    .show-on-hover {
      visibility: visible;
    }
  }
}

// Set text to primary when hovering
.hover-text-primary {
  &:hover {
    color: $byggtjanst-primary;
  }
}

.line-height-default {
  line-height: $line-height-default;
}

.max-width-80 {
  max-width: 80% !important;
}

// Flip the arrow when collapsed
.collapse-arrow {
  transition: all 0.5s;
}

.btn.collapsed .collapse-arrow.start-open {
  transform: rotate(-90deg);
}

.btn:not(.collapsed) .collapse-arrow.start-closed {
  transform: rotate(90deg);
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

/**
* Bootstrap v5 changed how cols work and they are no longer relative.
* The structure branches were created with boostrap v4,
* so restore old behaviour for structure cols.
*/
.branches-fix {
  position: relative;
}

.font-weight-700 {
  font-weight: 700;
}

// Folder icon and its predecessor
.folder {
  width: 40px;

  &-item {
    height: 40px;

    button {
      z-index: 3;
    }
  }
}
