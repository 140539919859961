// Customize bootstrap theme for Byggtjänst colors and layout.
// Colors

$link-color: $byggtjanst-darkblue;
$gray-100: $byggtjanst-gray-one;
$gray-200: $byggtjanst-gray-two;
$gray-300: $byggtjanst-gray-three;
$gray-400: $byggtjanst-gray-four;
$gray-500: $byggtjanst-gray-five;
$gray-600: $byggtjanst-gray-six;
$gray-700: $byggtjanst-gray-eight; // seven ate nine
$gray-800: $byggtjanst-gray-eight;
$gray-900: $byggtjanst-gray-nine;
$black: $gray-900;
$text-muted: $byggtjanst-gray;
$border-color: $canvas-gray;

$theme-colors: (
  'primary': $byggtjanst-primary,
  'success': $byggtjanst-darkblue,
  'danger': $byggtjanst-red,
  'warning': $byggtjanst-orange,
  'info': $byggtjanst-darkblue,
  'gray': $byggtjanst-gray,
  'blue ': $byggtjanst-blue,
  'secondary': $byggtjanst-gray,
  'green': $byggtjanst-primary,
  'light': $byggtjanst-gray-two,
  'dark': $gray-900,
  'lighter': $byggtjanst-gray-lighter,
  'purple': $byggtjanst-purple,
);

// Variable overrides
$btn-line-height: 1.75;
$btn-border-radius: 8px;
$modal-content-border-radius: 0;
$card-cap-bg: $byggtjanst-gray-one;
$box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
$btn-focus-box-shadow: inset 0 0 0 0.05rem white;
$nav-tabs-border-width: 0;
$link-decoration: none;
$link-hover-decoration: underline;
$enable-negative-margins: true;
$dropdown-link-hover-bg: $byggtjanst-gray-lighter;
$border-radius: 8px;
$h5-font-size: $base-font-size * 1.3;

// Bootstrap customizes containers in navbars. But we have modals in navbar and we don't want those to be affected.
.navbar .modal {
  .container,
  .container-fluid {
    display: block;
  }
}

// Adopt to the custom height of buttons
input.form-control {
  height: 100%;
  line-height: $btn-line-height;
}

input.form-control::placeholder {
  color: $byggtjanst-gray-light;
}

// Have some left margin between the text and the button
.dropdown-toggle::after {
  margin-left: 0.5rem;
}

.dropdown-item {
  line-height: 3rem;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background: $byggtjanst-gray-ten !important;
  color: $byggtjanst-gray-mid !important;
  font-weight: 700 !important;
  border-radius: 8px !important;
}