// Use '@import "variables";' in your sass files to use bootstrap variables.

// For a list of all variables, check:
// 'node_modules/bootstrap/scss/variables';

$byggtjanst-font-family: Inter, sans-serif;

$base-font-size: 14px;
$body-text-font-size: 12px;
$header-navbar-height: 62px;
$header-navbar-ama-logo: 294px;
$header-navbar-byggtjanst-logo: 160px;
$line-height-default: 1.5;

$font-weight-normal: 400;
$font-weight-bold: 600;

$status-banner-height: 52px;

$navigator-item-height: 48px;
$navigator-item-border-width: 4px;
$navigator-item-border-parent-outer-width: 1px;
$breadcrumb-item-height: 35px;

$canvas-header-height: 48px;
$canvas-text-header-height: 53px;
$footer-height: 36px;
$canvas-structure-item-border-width: 4px;
$canvas-structure-item-height: 46px;

$canvas-selected-border-width: 3px;

$text-a4-width: 21cm;
$text-a4-margin: 1.5cm;

$pdf-preview-project-image-height: 100px;

$search-input-width: 345px;
$menu-width: 262px;
$menu-list-item: 44px;
$material-icon-line-height: 1;
$notifications-width: 430px;
$notification-list-height: 660px;
$notification-list-max-height: calc(100vh - 13.5rem);
$modal-min-width: 662px;

$participant-list-width: 265px;

$item-hover: #f1f1f1;
$sidepanel-width: 21%;
// $zindex-feedback - Previous value - 1202 - It is overlapping the side panel. So decreased to modal backdrop
// On modal open, the side panel should go behind the modal. So the following z-index has been choosen
$zindex-feedback: 1038;
$zindex-sidepanel: 1039;

$btn-width: 200px;
$btn-height: 44px;
$byggtjanst-primary: #008746;
$byggtjanst-darkgreen: #006638;
$byggtjanst-lightgreen: #00a952;
$byggtjanst-interactivegreen: #00cc5f;
$byggtjanst-red: #d51317;
$byggtjanst-orange: #f39313;
$byggtjanst-lightblue: #73b8d2;
$byggtjanst-darkblue: #24608b;
$byggtjanst-lime: #95c11f;
$byggtjanst-blue: #2f80ed;
$byggtjanst-purple: #ba68c8;

// These colors are taken from the latest assets colors
$byggtjanst-gray-lighter: #e7e7e7;
$byggtjanst-gray-light: #bebebe;
$byggtjanst-gray-mid: #9a9a9a;
$byggtjanst-gray: #757575;
$byggtjanst-gray-mid-light: #e0e0e0;
$byggtjanst-gray-active: #cecece;

// Canvas gray is different. Don't ask me why
$canvas-gray: #d9d9d9;
$errorBorderOrange: #ffc7a6; // Border color used for validation error dialogue
$errorBorderRed: #e25a5d;

/*
 These colors are copied from byggtjanst.se-repo
 and merged with the latest assets colors
 */
$byggtjanst-gray-one: #f5f5f5;
$byggtjanst-gray-two: $byggtjanst-gray-light;
$byggtjanst-gray-three: #8b8f96;
$byggtjanst-gray-four: $byggtjanst-gray-mid;
$byggtjanst-gray-five: $byggtjanst-gray;
$byggtjanst-gray-six: #3c4248;
$byggtjanst-gray-eight: #1c2227;
$byggtjanst-gray-nine: #333;
$byggtjanst-gray-ten: #f2f2f2;

$byggtjanst-primary-palette: (
  50: $byggtjanst-interactivegreen,
  100: $byggtjanst-interactivegreen,
  200: $byggtjanst-lightgreen,
  300: $byggtjanst-lightgreen,
  400: $byggtjanst-primary,
  500: $byggtjanst-primary,
  600: $byggtjanst-primary,
  700: $byggtjanst-darkgreen,
  800: $byggtjanst-darkgreen,
  900: $byggtjanst-darkgreen,
  A100: #89ffa8,
  A200: #56ff83,
  A400: #23ff5e,
  A700: #09ff4b,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);

// Auto generated palette from base $byggtjanst-red
$byggtjanst-red-palette: (
  50: #fae3e3,
  100: #f2b8b9,
  200: #ea898b,
  300: #e25a5d,
  400: #db363a,
  500: $byggtjanst-red,
  600: #141212,
  700: #ca0e11,
  800: #c40b0d,
  900: #ba0607,
  A100: #ffe4e4,
  A200: #ffb1b1,
  A400: #ff7e7f,
  A700: #ff6465,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

@import 'custom-bootstrap.scss';
